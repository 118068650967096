import React, { useState, useEffect } from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {H1, H2, H3, P, Button} from "../components/Typography"
import {GatsbyImage} from 'gatsby-plugin-image'
import styled, {css} from "styled-components"
import ContentCard from "../components/ContentCard"
import NoResultCard from "../components/NoResultCard"
import SEO from "../components/seo"

const ContentList = styled.ul`
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(6 / 1680 * 100vw)};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: calc(12 / 1680 * 100vw);
    grid-column: 1 / span 12;

    :hover > li {
      opacity: 0.5;
    }

    > li:hover {
      opacity: 1;
    }
  }
`

const TopSquare = styled.div`
  display: none;

  @media ${props => props.theme.breakpoints.desktop} {
    display: block; 
    grid-row: 1 / span 1; 
    grid-column: 2 / span 1;
    padding-bottom: 0;
    width: 100%;
    z-index: 99;
    border: 1px solid ${props => props.theme.colors.foreground};
    background-color: ${props => props.theme.colors.background};
    border-radius: 10px;
  }

  :after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`
const SmallCircle = styled(Link)`
  position: relative;
  grid-row: 1 / span 1; 
  grid-column: 1 / span 3;
  padding-bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.foreground};
  align-self: start;
  border-radius: 50%;
  pointer-events: none;
  display: none;

  > #backarrow {
    display: none;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    grid-row: 1 / span 1; 
    grid-column: 1 / span 1;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > #backarrow {
      display: block;
    }
  
    :hover .cls-1 {
      fill: ${props => props.theme.colors.background};
    }
    .cls-1 {
      fill: ${props => props.theme.colors.foreground};
    }
  }
`
const ArrowSvg = styled.div`
    width: 20%;
    padding-top: 5px;
    margin-left: auto;
    margin-right: auto;
    transform: scale(-1, 1);
`

const FilterBanner = styled.div`
  grid-column: 1 / span 6;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(6 / 1680 * 100vw)};
  padding-bottom: 0;
  margin-bottom: ${props => props.theme.spacings.large};
  // border-bottom: 1px solid ${props => props.theme.colors.gray2};
  position: relative;

  @media ${props => props.theme.breakpoints.desktop} {
      grid-template-columns: repeat(12,minmax(0,1fr));
      column-gap: calc(12 / 1680 * 100vw);
      grid-column: 1 / span 12;
      border-top: none;
      padding-bottom: 0;
      margin-bottom: calc(12 / 1680 * 100vw);
  }
`

const FilterArea = styled.div`
  grid-column: 1 / span 6;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: 1fr auto auto;
  column-gap: calc(6 / 1680 * 100vw)};
  align-items: baseline;

  @media ${props => props.theme.breakpoints.desktop} {
      grid-template-columns: repeat(12,minmax(0,1fr));
      column-gap: calc(12 / 1680 * 100vw);
      grid-column: 1 / span 12;
      padding-bottom: 0;
      border-top: none;

      :before, :after {
          display:none;
      }
  }
`
const MetadataFilter = styled(Button)`
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  display: block;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.gray3};
  margin-right: 2px;
  margin-bottom: 2px;
  text-align: left;

  @media ${props => props.theme.breakpoints.desktop} {
    :hover {
      background-color: ${props => props.theme.colors.gray2};
    }
  }
`
const TypeLabel = styled(P)`
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  font-weight: 450;
  text-align: left;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    text-align: left;
  }
`
const SectorLabel = styled(P)`
  grid-column: 4 / span 3;
  grid-row: 2 / span 1;
  font-weight: 450;
  padding-bottom: ${props => props.theme.spacings.xSmall};
  text-align: left;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 6 / span 1;
    grid-row: 2 / span 1;
    text-align: left;
  }
`

const TypeFilters = styled.ul`
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  text-align: left;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  position: relative;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 3;
    grid-row: 3 / span 1;
  }
`
const SectorFilters = styled.ul`
  grid-column: 4 / span 3;
  grid-row: 3 / span 1;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 6 / span 3;
    grid-row: 3 / span 1;
  }
`
const ImagesPreviewBox = styled.div`
  display: none;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 9 / span 4;
    grid-row: 1 / span 3;
    text-align: left;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    position: relative;
    align-self: end;
  }
`

const LegoPart = styled.div`
  width: 7%;
  max-width: 40px;
  min-width: 25px;
  margin-right: ${props => props.theme.spacings.xxSmall};
  margin-bottom: ${props => props.theme.spacings.xxSmall};

  > div {
    border-radius: 5px;
  }
`

const MoreButtonDiv = styled(Button)`
  grid-column: 1 / span 6;
  grid-row: span 1;
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  display: block;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.gray3};
  text-align: center;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 10;
    :hover {
      background-color: ${props => props.theme.colors.gray2};
    }
  }
`


const pcenarrow = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 179.4 154.8'><title>PcEnArrow</title><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path class='cls-1' d='M119.4,154.8,109,146.4a275.79,275.79,0,0,1,24.2-30.6A145.53,145.53,0,0,1,157,94.6l-14.4.9q-7.8.5-17,.5H54.2a72.88,72.88,0,0,1-23.3-3.4,48.08,48.08,0,0,1-17-9.6A39.31,39.31,0,0,1,3.5,67.9,54,54,0,0,1,0,48,54,54,0,0,1,3.5,28.1,40.2,40.2,0,0,1,13.9,12.9a46,46,0,0,1,17-9.6A75,75,0,0,1,54.2,0h10V13h-10Q34,13,23.5,22.1T13,48q0,16.61,10.5,25.8T54.2,83h71.4q9.19,0,17,.4t14.4.8A145.53,145.53,0,0,1,133.2,63,275.79,275.79,0,0,1,109,32.4L119.4,24a296.33,296.33,0,0,0,18.9,26.5,186.53,186.53,0,0,0,19.4,20.7,250,250,0,0,0,21.7,17.6V90a216.8,216.8,0,0,0-21.7,17.5,199.8,199.8,0,0,0-19.4,20.7A272.09,272.09,0,0,0,119.4,154.8Z'/></g></g></svg>"

const ActivitiesPage = ({pageContext, data}) => {
    const posts = data.allSanityPost.edges

    const sectors = posts.reduce(function(arr, ele) {
        [].push.apply(arr,
          ele.node.secteur.filter(function(v) {
            return arr.indexOf(v) === -1;
          })
        );
        return arr;
    }, [])
    
    const types = posts.reduce(function(arr, ele) {
      [].push.apply(arr,
        ele.node.type.filter(function(v) {
          return arr.indexOf(v) === -1;
        })
      );
      return arr;
    }, [])

    const [filteredposts, setPosts] = useState(posts);
    const [sortedPosts, setSortedPosts] = useState([]);
    const [filterByType, setFilterByType] = useState('all');
    const [filterBySector, setFilterBySector] = useState('all');


    useEffect(() => {

      const typeArray = []
      if (filterByType === 'all') {
        types.map((singleType, i) => (
          typeArray.push(singleType)
        ))
        setTypeActiveFilter('all');
      } else {
        typeArray.push(filterByType)
        setTypeActiveFilter(typeArray);
      }

      const sectorArray = []
      if (filterBySector === 'all') {
        sectors.map((singleSector, i) => (
          sectorArray.push(singleSector)
        ))
        setSectorActiveFilter('all');
      } else {
        sectorArray.push(filterBySector)
        setSectorActiveFilter(sectorArray);
      }

      const result = posts.filter((post) =>
        post.node.type.find(singleType => typeArray.includes(singleType)) &&
        post.node.secteur.find(singleSecteur => sectorArray.includes(singleSecteur)))

      if (result.length === 0) {
        setPresent("grid")
      } else {
        setPresent("none")
      }
      
      setSortedPosts(result)

    }, [filteredposts, filterByType, filterBySector]);

    const [typeIsActive, setTypeActiveFilter] = useState('all');
    const [sectorIsActive, setSectorActiveFilter] = useState('all');

    const [present, setPresent] = useState('none');

    const [displayList, setDisplayList] = useState(sortedPosts.slice(0, 10));

    useEffect(() => {
        setDisplayList(sortedPosts.slice(0, 10))
    },[sortedPosts]);

    // State to trigger load more
    const [loadMore, setLoadMore] = useState(false)
    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(sortedPosts.length > 10)
    // Load more button click
    const handleLoadMore = () => {
      setLoadMore(true)
    }
    // Handle loading more articles
    useEffect(() => {
      if (loadMore && hasMore) {
        const currentLength = displayList.length
        const isMore = currentLength < sortedPosts.length
        const nextResults = isMore
          ? sortedPosts.slice(currentLength, currentLength + 10)
          : []
        setDisplayList([...displayList, ...nextResults])
        setLoadMore(false)
      }
    }, [loadMore, hasMore]) //eslint-disable-line
    //Check if there is more
    useEffect(() => {
      const isMore = displayList.length < sortedPosts.length
      setHasMore(isMore)
    }, [displayList]) //eslint-disable-line
    // console.log(displayList)


    return (
        <Layout>
          <SEO 
          title="Activités"
          />
            <FilterBanner>
              <SmallCircle to="/">
                <ArrowSvg id="backarrow" dangerouslySetInnerHTML={{__html: pcenarrow}}/>
              </SmallCircle>
              <TopSquare/>
              <FilterArea>
                <TypeLabel context="smallinfo">Type</TypeLabel>
                <SectorLabel context="smallinfo">Domaine</SectorLabel>
                <TypeFilters>
                  <MetadataFilter context="smalltextbubble" value='all' onClick={e => setFilterByType(e.target.value !== filterByType ? e.target.value : 'all')} style={typeIsActive === 'all' ? {backgroundColor: '#181818', color: '#e6e6e6'} : {}}>Tous</MetadataFilter>
                  {types.map((type, i) => (
                      <MetadataFilter key={type} context="smalltextbubble" value={type} onClick={e => setFilterByType(e.target.value !== filterByType ? e.target.value : 'all')} style={typeIsActive == (type) ? {backgroundColor: '#181818', color: '#e6e6e6'} : {}}>
                            {type}
                      </MetadataFilter>
                  ))}
                </TypeFilters>
                <SectorFilters>
                  <MetadataFilter context="smalltextbubble" value='all' onClick={e => setFilterBySector(e.target.value !== filterBySector? e.target.value : 'all')} style={sectorIsActive === 'all' ? {backgroundColor: '#181818', color: '#e6e6e6'} : {}}>Tous</MetadataFilter>
                  {sectors.map(sector => (
                      <MetadataFilter key={sector} context="smalltextbubble" value={sector} onClick={e => setFilterBySector(e.target.value !== filterBySector ? e.target.value : 'all')} style={sectorIsActive == (sector) ? {backgroundColor: '#181818', color: '#e6e6e6'} : {}}>
                            {sector}
                      </MetadataFilter>
                  ))}
                </SectorFilters>
                <ImagesPreviewBox>
                  {sortedPosts.slice(0,20).map((post, i) => (
                    <LegoPart key={i}>
                      <GatsbyImage image={post.node.mainImage.asset.gatsbyImageData} alt=""/>
                    </LegoPart>
                  ))}
                  <LegoPart><P context="smalltext">{sortedPosts.length}</P></LegoPart>
                </ImagesPreviewBox>
              </FilterArea>
            </FilterBanner>
          <ContentList>
            {displayList.map(post => (
              <ContentCard key={post.node.slug.current}
                slug = {post.node.slug.current}
                title = {post.node.title}
                excerpt = {post.node.excerpt}
                image = {post.node.mainImage.asset.gatsbyImageData}
                date = {post.node.publishedAt}
                authors = {post.node.authors}
                type = {post.node.type}
                sector = {post.node.secteur}
              />
            ))}
              <NoResultCard present = {present}
                title = "Aucun r&eacute;sultat"
                excerpt = "R&eacute;initialisez les filtres pour voir tous les articles"
              />
          </ContentList>
          {hasMore ? (
                  <MoreButtonDiv context="smalltextbubble" onClick={handleLoadMore}>
                        Voir plus
                  </MoreButtonDiv>
          ) : (null)}
        </Layout>
    )
}

export default ActivitiesPage

export const pageQuery = graphql`
    query AllPostsQuery {
        allSanityPost(sort: {fields: publishedAt, order: DESC}, skip: 0) {
            edges {
              node {
                mainImage {
                  asset {
                    gatsbyImageData(width: 800)
                  }
                }
                slug {
                  current
                }
                title
                excerpt
                publishedAt(formatString:"DD-MM-YYYY")
                authors {
                  firstname
                  surname
                }
                type
                secteur
              }
            }
        }
    }
`