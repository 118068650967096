import React from "react"
import {Link} from "gatsby"
import {H1, H2, H3, P} from "./Typography"
import styled from "styled-components"

const ContentItem = styled.div`
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: auto;
    column-gap: calc(6 / 1680 * 100vw)};
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
    border-top: 1px solid ${props => props.theme.colors.gray2};
    border-bottom: 0;
    align-items: baseline;
    position: relative;
    
    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(12,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 1 / span 12;
        grid-row: span 1;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 0;
        border-bottom: 0;
        border-top: 0;

        :hover > .infogrid {
            border-top: 1px solid ${props => props.theme.colors.secondary};
        }

        :hover > .content-img {
            z-index: 9;
            visibility: visible;
            transform: translateY(-20px);
            transition: transform 0.3s;
        }
    }
`
const InfoGrid = styled.div`
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: repeat(6,minmax(0,1fr));
    grid-auto-rows: auto;
    column-gap: calc(6 / 1680 * 100vw)};
    padding-top: ${props => props.theme.spacings.large};
    padding-bottom: ${props => props.theme.spacings.large};
    border-bottom: 0;
    margin-bottom: 0;
    align-items: baseline;
    position: relative;
    
    @media ${props => props.theme.breakpoints.desktop} {
        grid-template-columns: repeat(10,minmax(0,1fr));
        column-gap: calc(12 / 1680 * 100vw);
        grid-column: 3 / span 10;
        grid-row: span 1;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: ${props => props.theme.spacings.large};
        padding-bottom: ${props => props.theme.spacings.xLarge};
        border-bottom: 0;
        border-top: 1px solid ${props => props.theme.colors.gray2};
        :hover {
            border-top: 1px solid ${props => props.theme.colors.foreground};
        }
    }
`

const ContentHeader = styled.div`
    grid-column: 1 / span 6;
    grid-row: 1 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        grid-column: 1 / span 5;
        grid-row: 1 / span 1;
    }
`

const ContentTitle = styled(H3) `
    padding-top: 0;
    padding-bottom: ${props => props.theme.spacings.small};

    @media ${props => props.theme.breakpoints.desktop} {
        padding-top: 0;
        padding-bottom: ${props => props.theme.spacings.small};
    }
`

const ContentExcerpt = styled(P)`
    padding-bottom: ${props => props.theme.spacings.large};

    @media ${props => props.theme.breakpoints.desktop} {
        padding-top: 0;
        padding-bottom: 0;
    }
`

const NoResultCard = ({present, title, excerpt}) => {
    
    return (
        <ContentItem style={{display: present}}>
            <InfoGrid className='infogrid'>
                <ContentHeader>
                    <ContentTitle>{title}</ContentTitle>
                    <ContentExcerpt context="block">
                        {excerpt}
                    </ContentExcerpt>
                </ContentHeader>
            </InfoGrid>
        </ContentItem>
    )
};

export default NoResultCard